import axios from 'axios';

//const internalUrl = 'http://localhost:5173/dist/';
//const externalUrl = 'https://kelleher-frontend-forms.onrender.com';
const FORM_SCRIPTS_URL = import.meta.env.VITE_FORM_SCRIPTS_URL;

const fetchManifest = async () => {
  try {
    const response = await axios.get(`${FORM_SCRIPTS_URL}/manifest.json`);
    const manifestData = response.data;

    const formUrl = manifestData['form.js']?.file;

    if (formUrl) {
      // Create a new script element
      const script = document.createElement('script');
      // Set the src attribute to the concatenated externalUrl and formUrl
      script.src = `${FORM_SCRIPTS_URL}/${formUrl}`;
      // Append the script element to the head of the document
      document.head.appendChild(script);
    } else {
      console.error('form.js not found in manifest');
    }

    return manifestData;
  } catch (error) {
    console.error('Error fetching manifest:', error);
    throw error;
  }
};

// Fetch the manifest when the script is loaded
fetchManifest();
